<template>
  <div class="container">
    <van-nav-bar
      title="集团要闻"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="wrapper" v-for="(item, index) in newsData" :key="index">
      <news-item :data="item" />
    </div>
  </div>
</template>

<script>
import NewsItem from "@/components/NewsItem";
import axios from 'axios';

export default {
  name: "NewsList",
  data() {
    return {
      newsData: [],
    };
  },
  components: {
    NewsItem,
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      // this.$api.getNews().then((res) => {
      //   this.newsData = res;
      // });
      let a = window.location.href
      console.log(a)
      if(a == 'https://wap.jtgj.cc/#/newslist') {
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data
        }).catch(error =>{})
      }else if(a == 'http://wap.jtgj.cc/#/newslist'){
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data
        }).catch(error =>{})
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss">
.van-nav-bar .van-icon{
  color: black;
}
.van-nav-bar__title{
  font-size: 18px;
  font-weight: bold;
}
</style>